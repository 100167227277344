import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  MenuCard,
  CartCounter,
  Loading,
  Button,
  Menu,
  FoodAddon,
} from "../../components/components";
import localStorage from "../../services/cart";
import { FOOD_IMAGE_ROOT_URL as IMAGE_ROOT_URL } from "../../services/api";
import { HotelContext } from "../../App";

import arrow from "../../assets/icons/arrow-back.svg";

import styles from "./orderFood.module.css";
import provider from "./data.provider";

const OrderFood = ({ items = [] }) => {
  const [menu, toggleMenu] = useState(false);
  const [selectedAddonItem, setAddonItem] = useState(null);
  const [_, forceRender] = useState(Math.random());
  const { translations } = useContext(HotelContext);

  const history = useHistory();
  const sessionID = localStorage.initiate();

  if (!items.length) {
    return <Loading />;
  }

  const getSubcategories = () => {
    const subcategories = items.map((category) => category.sub_categories);
    const subcategoriesNamesAndIds = subcategories
      .flat()
      .map((subcategory) => ({ name: subcategory.name, id: subcategory.id }));
    return subcategoriesNamesAndIds;
  };

  const increaseQuantity = (quantity, item) => {
    forceRender(Math.random);
    setAddonItem(null);
    if (item.sub_addons.length) {
      setAddonItem(item);
      return;
    }
    localStorage.modify(sessionID, {
      ...item,
      quantity,
    });
  };

  const increaseQuantityByAddons = (quantity, item) => {
    setAddonItem(null);

    const price = item.price || item.addonPrice.price

    const itemToCheckout = { ...item, price, addons: item.addons, quantity: 1 };
    localStorage.add(sessionID, itemToCheckout);
    return;
  };

  const decreaseQuantity = (quantity, item) => {
    forceRender(Math.random);
    if (item.sub_addons.length) {
      localStorage.removeOne(sessionID, {
        ...item,
      });
      return;
    }

    if (quantity === 0) {
      localStorage.remove(sessionID, {
        ...item,
      });
      return;
    }
    localStorage.modify(sessionID, {
      ...item,
      quantity,
    });
  };

  const getDefaultValue = (itemID) => {
    const allItems = localStorage.get(sessionID);
    const foundItem = localStorage.getById(sessionID, itemID);
    if (foundItem && foundItem.addons) {
      return allItems.filter(item => item.id == itemID).length
    }
    return foundItem ? foundItem.quantity : 0;
  };

  const cartLength = () => {
    const allItems = localStorage.get(sessionID);
    return allItems.length;
  };

  const cartLengthAddons = () => {
    const allItems = localStorage.get(sessionID);
    const sumCounter = allItems.map((i) => i.quantity).reduce((a, b) => a + b);
    return sumCounter;
  };

  const renderFoods = (subcategories) => {
    return subcategories.map((subCat) => {
      return !subCat.enabled ? <div/> : (
        <div className={styles.saladsBox} key={Math.random()}>
          <div className={styles.saladsBoxTitle}>
            <span></span>
            <p id={subCat.id}>{subCat.name}</p>
            <span></span>
          </div>
          <div className={styles.dishesCards}>
            {subCat.items.map((foodEntry) => (
              <MenuCard
                {...foodEntry}
                key={Math.random()}
                defaultQuantity={getDefaultValue(foodEntry.id)}
                increaseQuantity={(quantity) =>
                  increaseQuantity(quantity, foodEntry)
                }
                decreaseQuantity={(quantity) =>
                  decreaseQuantity(quantity, foodEntry)
                }
              />
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className={selectedAddonItem? styles.fixedLayout : styles.orderFoodLayout}>
        <div className={styles.menuButtonWrapper}>
          {menu && (
            <>
              <div
                className={styles.overlay}
                onClick={() => toggleMenu(false)}
              ></div>{" "}
              <div className={styles.menuWrapper}>
                <Menu
                  subcategories={getSubcategories()}
                  onClose={() => toggleMenu(false)}
                />
              </div>
            </>
          )}
          <Button
            label="Menu"
            type="placeRequest"
            onClick={() => toggleMenu(!menu)}
          />
        </div>
        <img
          className={styles.arrowBack}
          onClick={() => history.push("/")}
          src={arrow}
          alt="back"
        />
        {items.map((category) => (
          <>
          <img className={styles.banner} src={IMAGE_ROOT_URL + category.image} alt="Menu Banner" />
            <div className={styles.menuMainTitle}>
              <h6>{category.name}</h6>
              <p>{category.description}</p>
            </div>
            {renderFoods(category.sub_categories)}{" "}
          </>
        ))}
        {cartLength() ? (
          <div className={styles.cartCounterWrapper}>
            <CartCounter
              type="viewCart"
              label={translations.items}
              cta={translations.foodViewCart}
              onClick={() => history.push("/checkout-food")}
              count={cartLengthAddons()}
            />
          </div>
        ) : (
          <div />
        )}{" "}
      </div>
      {selectedAddonItem && (
        <FoodAddon
          foodItem={selectedAddonItem}
          onClose={() => setAddonItem(null)}
          translations={translations}
          addSelected={(quantity, item) =>
            increaseQuantityByAddons(quantity, item)
          }
        />
      )}
    </>
  );
};

export default provider(OrderFood);
