import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { HotelContext } from "../../App";

import home from "../../assets/icons/home.svg";
import phone from "../../assets/icons/phone.svg";
import cart from "../../assets/icons/cart.svg";

import styles from "./bottomNav.module.css";

const BottomNav = ({ count }) => {
  const history = useHistory();
  const { hotelDetails } = useContext(HotelContext);
  const mobile = `tel:+92${hotelDetails.mobile}`;

  return (
    <nav className={styles.bottomNav}>
      <img
        className={styles.navIcon}
        onClick={() => history.push("/")}
        src={home}
      />

      <a href={mobile}>
        <img className={styles.navIcon} src={phone} />
      </a>

      <img
        className={styles.navIcon}
        onClick={() => history.push("/checkout-food")}
        src={cart}
      />
      {count && <span className={styles.badge}>{count}</span>}
    </nav>
  );
};

export default BottomNav;
