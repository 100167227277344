import React from "react";
import styles from "./input.module.css";

const TextInput = ({ placeholder, type, min, step, value, onChange, isEmpty, useValidation, validator }) => {

  return (
    <div className={type==='text' && !isEmpty ? styles.inputWrapperEmpty : styles.inputWrapper}>
      <input
        className={type==='email' && useValidation && !validator.test(value) ? styles.error : styles.success}
        type={type}
        min={min}
        step={step}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextInput;
