import React from "react";
import styles from "./checkbox.module.css";

const Checkbox = ({ id, name, value, label, styleType, type, onChange }) => {
  return (
    <div
      className={
        styleType === "addon"
          ? styles.checkboxAddonWrapper
          : styles.checkboxWrapper
      }
    >
      <input
        type={type}
        id={id}
        name={name}
        checked={value}
        onChange={onChange}
      />
      <label for={id}> {label}</label>
    </div>
  );
};

export default Checkbox;
