import React from 'react'
import styles from './card.module.css'

const Card = ({cover, heading}) => {
    return (
    <article className={styles.card}>
        <img className={styles.cover} src={cover} alt={heading}/>
        <div className={styles.headingWrapper}>
            <h3>{heading}</h3>
        </div>
    </article>  );
}
 
export default Card;