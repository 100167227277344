import React, { useState } from "react";
import styles from "./foodAddon.module.css";

import translations from "../../assets/mock-translations.json";
import { Button, Checkbox } from "../components";

const FoodAddon = ({ foodItem, onClose, addSelected }) => {
  const [selections, updateSelection] = useState({});
  const [selectionPrice, updateSelectionPrice] = useState([]);

  return (
    <div className={styles.foodAddonWrapper}>
      <div className={styles.foodAddonWindow}>
        <span onClick={onClose} className={styles.closeAddon}>
          X
        </span>
        <h2>{foodItem.name}</h2>
        <div className={styles.choices}>
          {foodItem.sub_addons.map((addon) => {
            const availableIds = addon.addons.map(add => add.id)
            const exceedingLength = addon.max_addon_per_order === Object.keys(selections).filter(
              n => selections[n] && availableIds.includes(parseInt(n))
              ).length;
            const updateAddonSelection = (option) => {
              updateSelectionPrice(existing => [...existing, { id: option.id, price: option.price }])
              if (addon.type === "single_select") {
                let otherOptionsObject = {};
                addon.addons.forEach((add) => {
                  otherOptionsObject[add.id] = false;
                });
                return updateSelection({
                  ...selections,
                  ...otherOptionsObject,
                  [option.id]: !selections[option.id],
                });
              }
              return updateSelection({
                ...selections,
                [option.id]: !selections[option.id],
              });
            };
            return (
              <div>
                <h3>{addon.name}</h3>
                {exceedingLength ? <p className={styles.errorMessage}>{translations['foodLimitExtended']}</p> : <div/>}
                {addon.addons.map((option) => (
                  <div className={styles.choiceRow}>
                    <Checkbox
                      type={
                        addon.type === "single_select" ? "radio" : "checkbox"
                      }
                      styleType="addon"
                      label={option.name}
                      value={selections[option.id] || false}
                      onChange={!selections[option.id] && exceedingLength ? () => null : () => updateAddonSelection(option)}
                    />
                    {option.price ? (
                      <span>
                        {translations.currency}
                        {option.price}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <div className={styles.addButtonWrapper}>
          <Button
            label="Add"
            onClick={() =>
              addSelected(foodItem.quantity || 1, {
                ...foodItem,
                addonPrice: selectionPrice.find(item => item.id == Object.keys(selections).filter(n => selections[n])[0]),
                addons: selections,
              })
            }
            type="proceed"
          />
        </div>
      </div>
    </div>
  );
};

export default FoodAddon;
