import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Checkbox, Button, TextInput } from "../../components/components";
import arrow from "../../assets/icons/arrow-back.svg";
import menuBanner from "../../assets/images/reception.jpg";
import provider from "./data.provider";

import { HotelContext } from "../../App";

import styles from "./placeRequest.module.css";

const PlaceRequest = ({ content = [] }) => {
  const history = useHistory();
  const [checkboxState, setCheckbox] = useState({});
  const [detailedBox, selectDetailedBox] = useState(null);

  const { translations } = useContext(HotelContext);

  const updateCheckboxState = (entry) => {
    if (["with-count", "open-msg"].includes(entry.type)) {
      selectDetailedBox(entry);
      return;
    }
    const selectedItem = checkboxState[entry.id];
    selectDetailedBox(null);
    return setCheckbox({
      ...checkboxState,
      [entry.id]: {
        requested: selectedItem ? !selectedItem.requested : true,
        value: "",
      },
    });
  };

  const blockNonPositiveNumbers = (num) => {
    const number = parseInt(num);

    if (isNaN(number) || number < 1) {
      return 1;
    }
    return num;
  };

  return (
    <div className={styles.placeRequestLayout}>
      <img
        className={styles.arrowBack}
        onClick={() => history.push("/")}
        src={arrow}
        alt="back"
      />
      {detailedBox && (
        <div className={styles.bottlesOverlayInput}>
          <div className={styles.promptBox}>
            {translations.placeRequestModalQuestion}
            <TextInput
              type={detailedBox.type === "with-count" ? "number" : "text"}
              placeholder={translations.placeRequestModalPlaceholder}
              value={checkboxState[detailedBox.id] ? checkboxState[detailedBox.id].value : ''}
              onChange={(e) =>
                setCheckbox({
                  ...checkboxState,
                  [detailedBox.id]: {
                    requested: true,
                    value:
                      detailedBox.type === "with-count"
                        ? blockNonPositiveNumbers(e.target.value)
                        : e.target.value,
                  },
                })
              }
            />
            <div className={styles.promptButtons}>
              <span onClick={() => selectDetailedBox(null)}>
                {translations.placeRequestModalCancel}
              </span>
              <span onClick={() => selectDetailedBox(null)}>
                {translations.placeRequestModalDone}
              </span>
            </div>
          </div>
        </div>
      )}
      <img className={styles.banner} src={menuBanner} alt="Menu Banner" />
      <div className={styles.questionaire}>
        {content.map((item) => (
          <Checkbox
          type="checkbox"
            label={item.name}
            id={item.id}
            name={item.name}
            onChange={() => updateCheckboxState(item)}
          />
        ))}
      </div>
      <div className={styles.placeRequestButton}>
        <Button
          label={translations.placeRequestContinue}
          type="placeRequest"
          onClick={() =>
            history.push("/checkout-place-request", {
              content,
              selectedRequests: checkboxState,
            })
          }
        />
      </div>
    </div>
  );
};

export default provider(PlaceRequest);
