import React from "react";
import LS from "../../services/cart";
import {
  postPrivateResourceFormData,
  postPrivateResource,
} from "../../services/api";

export const FoodProvider = (ConnectedComponent) => () => {
  const sessionID = LS.initiate();
  const cartContent = LS.get(sessionID);
  const hotelID = localStorage.getItem("hotel_id") || false;
  const roomNumber = localStorage.getItem("room_no") || "";

  const submitRequest = async (
    attachment,
    { entries, guests, instructions, paymentStatus }
  ) => {
    await postPrivateResourceFormData(
      "/ird/guest/order",
      {
        enc_hotel_id: hotelID,
        room_no: roomNumber,
        items: JSON.stringify(entries),
        no_of_guest: guests,
        payment_status: paymentStatus,
        description: instructions,
      },
      {
        type: paymentStatus === "done" ? "payment_receipt" : "signature",
        file: attachment
      }
    );
  };

  return (
    <ConnectedComponent
      submitRequest={submitRequest}
      defaultEntries={cartContent}
      roomNumber={roomNumber}
    />
  );
};

export const LaundryProvider = (ConnectedComponent) => ({ location }) => {
  const hotelID = localStorage.getItem("hotel_id") || false;
  const roomNumber = localStorage.getItem("room_no") || "";

  const placeOrder = async ({ pickup, items }) => {
    await postPrivateResource("/wash/laundry/guest/order", {
      enc_hotel_id: hotelID,
      room_no: roomNumber,
      requested_pickup_at: pickup,
      items: JSON.stringify(items),
    });
  };

  return (
    <ConnectedComponent
      flattenedItems={location.state || {}}
      placeOrder={placeOrder}
      roomNumber={roomNumber}
    />
  );
};

export const SpaProvider = (ConnectedComponent) => ({ location }) => {
  const hotelID = localStorage.getItem("hotel_id") || false;
  const roomNumber = localStorage.getItem("room_no") || "";

  const placeOrder = async ({ schedule, item, guestNumber }) => {
    await postPrivateResource("/sanitarium/spa/guest/order", {
      enc_hotel_id: hotelID,
      room_no: roomNumber,
      request_schedule_at: schedule,
      items: JSON.stringify([item]),
      no_of_guest: guestNumber,
    });
  };

  return (
    <ConnectedComponent
      selectedItem={location.state || {}}
      placeOrder={placeOrder}
      roomNumber={roomNumber}
    />
  );
};

export const PlaceRequestProvider = (ConnectedComponent) => ({ location }) => {
  const hotelID = localStorage.getItem("hotel_id") || false;
  const roomNumber = localStorage.getItem("room_no") || "";

  const placeOrder = async ({ items, guestNumber }) => {
    await postPrivateResource("/connect/guest/order", {
      enc_hotel_id: hotelID,
      room_no: roomNumber,
      items: JSON.stringify(items),
      no_of_guest: guestNumber,
    });
  };

  return (
    <ConnectedComponent
      data={location.state || {}}
      placeOrder={placeOrder}
      roomNumber={roomNumber}
    />
  );
};