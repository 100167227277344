import React, { useState, useEffect, useContext } from "react";
import SignatureInstance from "signature_pad";
import toBuffer from "image-data-uri";

import { FoodProvider as DataProvider } from "./checkout.provider";
import { HotelContext } from "../../App";
import LS from "../../services/cart";

import {
  CheckoutCard,
  Counter,
  TextArea,
  Button,
  Loading,
  Switch,
} from "../../components/components";

import confirmationStyles from "./food.module.css";
import styles from "./checkout.module.css";
import { useHistory } from "react-router-dom";
import upload from "../../assets/icons/upload.svg";

const Checkout = ({ defaultEntries = [], submitRequest, roomNumber }) => {
  const [instructions, updateInstructions] = useState("");
  const [guests, updateGuests] = useState(1);
  const [entries, setEntries] = useState(defaultEntries);

  const [signaturePad, setSignaturePad] = useState(null);
  const [screenshot, setScreenshot] = useState(null);

  const [confirmNow, setConfirmationMethod] = useState(null);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
const [isChecked, setIsChecked] = useState(true);

  const { translations, hotelDetails } = useContext(HotelContext);
  const history = useHistory();

  useEffect(() => {
    const canvas = document.getElementById("signature-canvas");
    if (canvas) {
      const SignaturePad = new SignatureInstance(canvas);
      setSignaturePad(SignaturePad);
    }
  }, [confirmNow]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const modifyQuantity = (quantity, position) => {
    let contentCopy = entries;
    contentCopy[position].quantity = quantity;
    setEntries(() => [...contentCopy]);
    return;
  };

  const getTaxedPrice = ({ price, quantity, taxes = [] }) => {
    let sumPrice = price * quantity;
    if (taxes.length) {
      const sumTaxes = taxes.reduce((a,b) => a.tax.percentage + b.tax.percentage)
      const itemTax = sumPrice * (taxes.length > 1 ? sumTaxes / 100 : taxes[0].tax.percentage / 100);
      sumPrice = sumPrice + itemTax;
    }
    return sumPrice;
  };

  const getSumPayable = () => {
    const sessionID = LS.initiate();
    if (!entries.length) {
      LS.clear(sessionID);
      return 0;
    }
    
    const allWithTaxes = entries.map((item) => getTaxedPrice(item));
    const price = allWithTaxes.reduce((a, b) => a + b);

    if (!price) {
      LS.clear(sessionID);
      return 0;
    }
    return Math.floor(price);
  };
 
  const getAddonFormat = (addons) => {
    const addonArray = Object.keys(addons).map((key) => addons[key] ? ({
      menu_item_addon_id: key,
      quantity: 1,
    }) : null).filter(n => n);
    return addonArray;
  };

  const submitRequestHandler = async () => {
    setError("");
    setLoading(true);

    if (!confirmNow && signaturePad.isEmpty()) {
      setError(translations.ckGeneralError);
      setLoading(false);
      return;
    }

    if (confirmNow && !screenshot) {
      setError(translations.ckGeneralError);
      setLoading(false);
      return;
    }

    try {
      const dataURL = signaturePad.toDataURL();
      const signatureImageAsBlob = await toBuffer.decode(dataURL);
      const signatureImage = new File(
        [signatureImageAsBlob.dataBuffer],
        "signature.png",
        { type: "image/png" }
      );
      await submitRequest(confirmNow ? screenshot[0] : signatureImage, {
        entries: entries.map((entry) => ({
          menu_item_id: entry.id,
          quantity: entry.quantity,
          ...(entry.addons && { addons: getAddonFormat(entry.addons) }),
        })),
        guests,
        instructions,
        paymentStatus: confirmNow ? "done" : "offline",
      });
      const sessionID = LS.initiate();
      LS.clear(sessionID);
      history.push("/food-thank-you");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setError(translations.ckGeneralError);
      setLoading(false);
    }
  };

  const cartEmpty = !getSumPayable();

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className={styles.closeButton} onClick={() => history.push("/ird")}>
        X
      </div>

      <div className={styles.checkoutLayout}>
        {cartEmpty ? (
          <div className={styles.emptyCartScreen}>
            <p>{translations.ckFoodEmptyCard}</p>

            <Button
              label={translations.ckFoodEmptyButton}
              type="proceed"
              onClick={() => history.push("/ird")}
            />
          </div>
        ) : (
          <>
            <p className={styles.warning}>
              &#x26A0;{translations.ckFoodTaxWarning}
            </p>
            {entries.map((entry, position) => (
              <CheckoutCard
                {...entry}
                key={Math.random()}
                defaultQuantity={entry}
                inclTaxes={translations.totalTaxes}
                taxedPrice={getTaxedPrice(entry)}
                increaseQuantity={() =>
                  modifyQuantity(entry.quantity + 1, position)
                }
                decreaseQuantity={() =>
                  modifyQuantity(entry.quantity - 1, position)
                }
              />
            ))}
            <div className={styles.total}>
              <div>
                <p>{translations.totalPayable}</p>
                <h6 className={styles.taxDisclaimer}>
                  {translations.totalTaxes}
                </h6>
              </div>
              <h3>
                {translations.valute}
                {getSumPayable()}
              </h3>
            </div>
            <div className={styles.roomNumber}>
              <p className={styles.placeRequestContentBold}>
                {translations.ckGeneralRoomNumber}
              </p>
              <p>{roomNumber}</p>
            </div>
            <p>{translations.ckFoodInstructionsLabel}</p>
            <TextArea
              value={instructions}
              onChange={(e) => updateInstructions(e.target.value)}
            />
            <div className={styles.guestsCounter}>
              <p>{translations.ckGeneralGuests}</p>
              <Counter
                count={guests}
                increment={() => updateGuests(guests + 1)}
                decrement={guests === 1 ? null : () => updateGuests(guests - 1)}
              />
            </div>
            <div className={confirmationStyles.layout}>
              <div className={confirmationStyles.switchContainer}>
                <p>{translations.ckFoodSettleLater}</p>
                <Switch
                  value={confirmNow}
                  onChange={() => setConfirmationMethod(!confirmNow)}
                />
                <p>{translations.ckFoodSettleNow}</p>
              </div>
              <div className={confirmationStyles.orderContainer}></div>
              {confirmNow ? (
                <div className={styles.payNowWindow}>
                  <div className={styles.stepOne}>
                    <p className={styles.step}>{translations.ckStepOne}</p>
                    <p>{translations.ckStepOneContent}</p>
                    <a
                      href={hotelDetails.paytm_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.noLink}
                    >
                      <Button
                        label={translations.ckStepOneButton}
                        type="proceed"
                      />
                    </a>
                  </div>
                  <div className={styles.uploadFileWrapper}>
                    <p className={styles.step}>{translations.ckStepTwo}</p>
                    <p>{translations.ckStepTwoContent}</p>
                    <label htmlFor="file-upload" className={styles.uploadFile}>
                      <img src={upload} alt="Upload File Icon" />
                      <span>{translations.ckStepTwoButton}</span>
                    </label>

                    <input
                      id="file-upload"
                      type="file"
                      label={translations.ckStepTwoButton}
                      onChange={(e) => setScreenshot(e.target.files)}
                    />
                    <p className={styles.uploadedFileName}>
                      {screenshot && screenshot[0].name}
                    </p>
                  </div>
                  <div
                    className={
                      !screenshot ? styles.stepThreeDisabled : styles.stepThree
                    }
                  >
                    <p className={styles.step}>{translations.ckStepThree}</p>
                    <p>{translations.ckStepThreeContent}</p>
                    <Button
                      label={translations.ckStepThreeButton}
                      type="proceed"
                      onClick={screenshot ? () => submitRequestHandler() : null}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.signatureScreen}>
                    <div className={confirmationStyles.signatureButtonsWrapper}>
                      <p>{translations.ckFoodSignature}</p>

                      <button onClick={() => signaturePad.clear()}>
                        {translations.ckFoodClearSignature}
                      </button>
                    </div>
                    <canvas
                      id="signature-canvas"
                      className={confirmationStyles.signatureCanvas}
                    />
                  </div>
                  <div >
                   <input className={confirmationStyles.InputCheckBox}
                      type="checkbox"
                      onChange={(e) => setIsChecked(e.currentTarget.checked)}
                      checked={isChecked}
                    />
                    <label className={confirmationStyles.labelSize}>I will receive the order in 35 - 40 mins</label>
                  </div>
                  <div className={confirmationStyles.submitButtonWrapper}>
                    <button disabled={!isChecked} className={!isChecked ? confirmationStyles.disableBtn : ''} onClick={() => submitRequestHandler()}>
                      {translations.ckGeneralSubmitButton}
                    </button>
                  </div>
                </>
              )}
              {error && <p className={styles.checkoutFoodError}>{error}</p>}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DataProvider(Checkout);
