import React from "react"
import {useHistory} from 'react-router-dom'

import {Loading} from '../../components/components';

import provider from "./data.provider"
import arrow from "../../assets/icons/arrow-back.svg";

import styles from './reuse.module.css';

const Page = ({

    content = ''
}) => {
    const history = useHistory()
    if (!content) {
        return (
            <Loading/>
        )
    }
    return (
        <div className={styles.reuseLayout}>
               <img
                  className={styles.arrowBack}
                  onClick={() => history.push("/")}
                  src={arrow}
                  alt="back"
                />
            <div className={styles.reuse} dangerouslySetInnerHTML={{ __html: content }}/>
        </div>
    )
}


export default provider(Page)