import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { LaundryProvider as DataProvider } from "./checkout.provider";
import {
  CheckoutCard,
  Button,
  Loading,
  DatePickerComponent,
} from "../../components/components";
import { HotelContext } from "../../App";

import dateConfig from "./dateConfig";
import timeConfig from "./timeConfig";
import styles from "./laundryCheckout.module.css";

const Checkout = ({ flattenedItems = [], placeOrder, roomNumber }) => {
  const [immediatePU, toggleImmediatePU] = useState(false);
  const [PUDate, setPUDate] = useState("");
  const [PUHour, setPUHour] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [render, forceRender] = useState(Math.random())

  const { translations } = useContext(HotelContext);
  const history = useHistory();
  const [selectedItems, selectItems] = useState(flattenedItems);

  useEffect(() => {
    window.scroll(0, 0)}, [])

  if (!flattenedItems.length) {
    history.push("/");
    return <div />;
  }
  if (loading) {
    return <Loading />;
  }

  const getPrice = ({ price, quantity }) => {
    const sumPrice = price * quantity;
    return sumPrice;
  };

  const getSumPayable = () => {
    if (!flattenedItems.length) {
      return 0;
    }
    const allWithTaxes = flattenedItems.map((item) => getPrice(item));
    return allWithTaxes.reduce((a, b) => a + b);
  };

  const updateSelection = (quantity, id) => {
    const itemFromCatalogue = flattenedItems.find((item) => item.id === id);
    const alreadySelected = selectedItems.findIndex(
      (item) => item.id === itemFromCatalogue.id
    );

    if (alreadySelected == -1) {
      selectItems((contain) => [
        ...contain,
        {
          ...itemFromCatalogue,
          quantity: 1,
        },
      ]);
      return;
    }
    let mutableItems = selectedItems;
    const newQuantity = mutableItems[alreadySelected].quantity + quantity;

    mutableItems[alreadySelected].quantity = newQuantity;
    selectItems(() => mutableItems);
    return forceRender(Math.random())
  };

  const isAllowed = selectedItems.length;

  const placeOrderHandler = async () => {
    setLoading(true);
    setError("");
    try {
      await placeOrder({
        pickup: immediatePU
          ? moment().format("YYYY-MM-DD HH:mm:ss")
          : `${PUDate} ${PUHour}:00`,
        items: selectedItems
          .filter((entry) => entry.quantity)
          .map((entry) => ({
            laundry_item_id: entry.id,
            quantity: entry.quantity,
          })),
      });
      setLoading(false);
      return history.push("/laundry-thank-you");
    } catch (e) {
      setLoading(false);
      setError(translations.ckGeneralError);
      console.log(e);
    }
  };

  return (
    <>
      <div
        className={styles.closeButton}
        onClick={() => history.push("/laundry")}
      >
        X
      </div>
      <p className={styles.warning}>
              &#x26A0;{translations.ckFoodTaxWarning}
            </p>
      <div className={styles.checkoutLayout}>
     
        {error && (
          <div className={styles.errorMessage}>
            {translations.ckLaundryError}{" "}
          </div>
        )}
        <h3>{translations.ckLaundryTitle}</h3>
        {selectedItems.map((entry) => (
          <CheckoutCard
            {...entry}
            key={Math.random()}
            laundry
            defaultQuantity={selectedItems.find((item) => item.id === entry.id)}
            increaseQuantity={() => updateSelection(+1, entry.id)}
            decreaseQuantity={() => updateSelection(-1, entry.id)}
          />
        ))}
        <div className={styles.total}>
          <p>Total</p>
          <h3>
            {" "}
            {translations.valute}
            {getSumPayable()}{" "}
          </h3>
        </div>
        <div className={styles.pickUpLaundry}>
          <input
            className={styles.timeInputCheckbox}
            type="checkbox"
            onChange={() => [
              setPUDate(""),
              setPUHour(""),
              toggleImmediatePU(!immediatePU),
            ]}
          />
          <p>{translations.ckLaundryPickup}</p>
        </div>
        {!immediatePU && (
          <>
            <div className={styles.timeInputWrapper}>
              <p className={styles.selectTime}>
                {translations.ckLaundrySelectDate}{" "}
              </p>
              <DatePickerComponent
                placeholder={translations.selectDate}
                value={PUDate}
                config={dateConfig}
                confirmText={translations.ckTimepickerDone}
                cancelText={translations.ckTimepickerCancel}
                saveTime={time => setPUDate(time)}
                cType="date"
              />
            </div>
            <br />
            <div className={styles.timeInputWrapper}>
              <p className={styles.selectTime}>
                {translations.ckLaundrySelectTime}{" "}
              </p>
              <DatePickerComponent
                placeholder={translations.selectTime}
                value={PUHour}
                config={timeConfig}
                confirmText={translations.ckTimepickerDone}
                cancelText={translations.ckTimepickerCancel}
                errorText={translations.ckTimepickerError}
                saveTime={time => setPUHour(time)}
                cType="hour"
                minHour={8}
                maxHour={17}
              />
            </div>
          </>
        )}

        <div className={styles.roomNumber}>
          <p className={styles.placeRequestContentBold}>
            {translations.ckGeneralRoomNumber}{" "}
          </p>
          <p>{roomNumber}</p>
        </div>
        <div className={styles.checkoutBox}>
          <Button
            label={translations.ckGeneralBackButton}
            type="back"
            onClick={() => history.push("/laundry")}
          />
          <Button
            label={translations.ckGeneralSubmitButton}
            type="proceed"
            disabled={!isAllowed}
            onClick={isAllowed ? () => placeOrderHandler() : null}
          />
        </div>
      </div>
    </>
  );
};

export default DataProvider(Checkout);
