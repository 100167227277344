import React from 'react'
import styles from './switch.module.css'

const Switch = ({onChange}) => {

    return ( 
        <div className={styles.switchWrapper}>
    <label className={styles.switch}>
       <input type="checkbox" onChange={onChange}/>
       <span className={styles.slider}></span>
    </label> 
  </div>
  );
}

 
export default Switch;