/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { getPrivateResource } from "../../services/api";

const DataProvider = (ConnectedComponent) => () => {
  const [data, setData] = useState({});
  const hotelID = localStorage.getItem("hotel_id") || false;

  useEffect(() => {
    async function getEnhancedData() {
      const {
        data: { data },
      } = await getPrivateResource(`/wash/laundry/guest/menu/${hotelID}`);

      const allItems = data.categories.map(cat => cat["without_sub_category_items"])
      const flattenedItems = allItems.flat()

      setData({ 
        items: data.categories,
        flattenedItems
       });
    }
    getEnhancedData();
  }, [hotelID]);

  return <ConnectedComponent {...data} />;
};

export default DataProvider;
