/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react"
import { getPrivateResource } from "../../services/api"

const DataProvider = (ConnectedComponent) => () => {
    const [data, setData] = useState({})
    const hotelID = localStorage.getItem("hotel_id") || false;

  useEffect(() => {
    async function getEnhancedData() {
      const { data: { data }} = await getPrivateResource(`/ird/guest/menu/${hotelID}`);
      const [{ categories }] = data;
      setData({ items: categories })
  }
    getEnhancedData()
  }, [hotelID])
  return <ConnectedComponent {...data} />
}

export default DataProvider
