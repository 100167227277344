import React, { useContext } from "react";

import { Button } from "../components";
import { HotelContext } from "../../App";

import styles from "./spaCard.module.css";

const SpaCard = ({ name, price, onClick }) => {
  const { translations } = useContext(HotelContext);
  return (
    <article className={styles.spaCard}>
      <div className={styles.bookButtonWrapper}>
        <Button type="book" onClick={onClick} label={translations.ckSpaBook} />
      </div>
      <p>{name}</p>
      <h6>
        {translations.currency} {price}
      </h6>
    </article>
  );
};

export default SpaCard;
