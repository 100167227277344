import { v4 as uuid } from 'uuid';

const initiate = () => {
  const existingSessionID = Object.keys(localStorage).find((item) =>
    /hotel_cart/.test(item)
  );
  if (existingSessionID) {
    console.log(`using existing session: ${existingSessionID}`);
    return existingSessionID;
  }
  const sessionID = `hotel_cart${uuid()}`;
  localStorage.setItem(sessionID, JSON.stringify([]));
  console.log(`new session initiated: ${sessionID}`);
  return sessionID;
};

const add = (sessionID, payload) => {
  const content = JSON.parse(localStorage.getItem(sessionID)) || [];
  localStorage.setItem(sessionID, JSON.stringify([...content, payload]));
};

const remove = (sessionID, payload) => {
  const content = JSON.parse(localStorage.getItem(sessionID)) || [];
  const filteredContent = content.filter((item) => item.id !== payload.id);
  localStorage.setItem(sessionID, JSON.stringify(filteredContent));
};

const removeOne = (sessionID, payload) => {
  let i = 0;
  const content = JSON.parse(localStorage.getItem(sessionID)) || [];
  const filteredContent = content.filter((item) => {
    if (item.id == payload.id && i === 0) {
      i++;
      return false;
    }
    return true;
  });
  localStorage.setItem(sessionID, JSON.stringify(filteredContent));
};

const modify = (sessionID, payload) => {
  const content = JSON.parse(localStorage.getItem(sessionID)) || [];
  const modifyIndex = content.findIndex((item) => item.id === payload.id);
  if (modifyIndex !== -1) {
    let contentCopy = content;
    contentCopy[modifyIndex] = payload;
    localStorage.setItem(sessionID, JSON.stringify(contentCopy));
    return;
  }
  localStorage.setItem(sessionID, JSON.stringify([...content, payload]));
};

const clear = (sessionID) => {
  localStorage.setItem(sessionID, JSON.stringify([]));
};

const get = (sessionID) => {
  const content = localStorage.getItem(sessionID);
  return JSON.parse(content);
};

const getById = (sessionID, entryID) => {
  const content = localStorage.getItem(sessionID);
  const parsed = JSON.parse(content);
  return parsed.find(item => item.id === entryID)
}

export default {
  initiate,
  clear,
  get,
  add,
  remove,
  removeOne,
  modify,
  getById,
};
