import React from "react";
import Loader from "react-loader-spinner";

import styles from "./loading.module.css";

const Loading = () => {
  return (
    <div className={styles.loading}>
      <Loader type="TailSpin" color="#665551" height={75} width={75} />
    </div>
  );
};

export default Loading;
