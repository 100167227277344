import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { HotelContext } from "../../App";
import { IMAGE_ROOT_URL } from "../../services/api";

import styles from "./header.module.css";

const Header = () => {
  const { hotelDetails } = useContext(HotelContext);
  const history = useHistory();

  return (
    <header>
      <img
        onClick={() => history.push("/")}
        src={IMAGE_ROOT_URL + hotelDetails.logo}
        alt={`${hotelDetails.name} logo`}
        className={styles.logo}
      />
    </header>
  );
};

export default Header;
