import React, { useState } from "react";


import { WelcomeCard, Loading } from "../../components/components";

import cover from "../../assets/images/journey.png";
import arrow from "../../assets/icons/arrow-back.svg";

import styles from "./welcome.module.css";
import provider from "./data.provider";

const Welcome = ({ content = [] }) => {

 
  const [selectedContent, selectContent] = useState(null);



  if (!content.length) {
    return <Loading />;
  }
  const COLOR_WITH_TYPE = "backgroud_color";
 
  if (selectedContent !== null) {
    const html = content.find(item => item.id === selectedContent).content
    return (
      <div className={styles.welcomeLayout}>
        <img
          className={styles.arrowBack}
          onClick={() => selectContent(null)}
          src={arrow}
          alt="back"
        />
        <div dangerouslySetInnerHTML={{ __html: html}}/>
      </div>
    );
  }

  return (
    <div className={styles.welcomeLayout}>
      <img src={cover} alt=""  />
      {content.map((element) => (
        <WelcomeCard
          key={Math.random()}
          color={element[COLOR_WITH_TYPE]}
          name={element.name}
          description={element.description}
          id={element.id}
          onClick={() => selectContent(element.id)}
        />
      ))}
    </div>
  );
};

export default provider(Welcome);
