import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TextInput, Button, Loading } from "../../components/components";
import { HotelContext } from "../../App";

import styles from "./login.module.css";
import { login, IMAGE_ROOT_URL } from "../../services/api";


const EMAIL_REGEXP = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)


const Login = ({ location }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [lastName, setLastName] = useState("");

  const { translations, hotelDetails } = useContext(HotelContext);

  const hotelId = localStorage.getItem("hotel_id") || false;
  const history = useHistory();

  const forwardUrl = location.search ? location.search.split("=").pop() : "/";

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await login({ hotelId, email, lastName });
      localStorage.setItem("hotel_token", data.access_token);
      setError("");
      setLoading(false);
      history.push(`/${forwardUrl}`);
    } catch (e) {
      console.log(e);
      setError(translations.ckGeneralError);
      setLoading(false);
    }
  };
  return (
    <div className={styles.login}>
      <span className={styles.closeButton} onClick={() => history.push("/")}>
        X
      </span>
      <img
        src={IMAGE_ROOT_URL + hotelDetails.logo}
        alt={`${hotelDetails.name} logo`}
      />
      {!loading ? (
        <>
          <TextInput
            type="email"
            name="email"
            placeholder={translations.loginEmailPlaceholder}
            isEmpty={lastName.length > 0}
            value={email}
            useValidation
            validator={EMAIL_REGEXP}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            type="text"
            name="lastName"
            placeholder={translations.loginNamePlaceholder}
            value={lastName}
            isEmpty={lastName.length > 0}
            onChange={(e) => setLastName(e.target.value)}
          />
          <div className={styles.disclaimerWrapper}>
          <p>We do not save your email address in any form. Email address and your last name is required only to identify your unique requests during your stay.</p>
          </div>
          <div className={styles.buttonWrapper}>
            <Button

              label={translations.ckGeneralSubmitButton}
              onClick={() => handleSubmit()}
              type="login"
            />
          </div>
        </>
      ) : (
          <Loading />
        )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default Login;
