import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const hasToken = localStorage.getItem("hotel_token") || false;

  if (!hasToken) {
    return <Redirect to={`/login?pathSource=${rest.path.split("/").pop()}`} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;
