import React from "react"
import { useHistory } from "react-router-dom";

import provider from "./data.provider"
import arrow from "../../assets/icons/arrow-back.svg";

import styles from './connect.module.css'






const Page = ({
   
    content = ''
}) => {
    const history = useHistory();
    if (!content) {
        return (
            <div></div>
        )
    }
    return (
        <div className={styles.connectLayout}>
               <img
                  className={styles.arrowBack}
                  onClick={() => history.push("/")}
                  src={arrow}
                  alt="back"
                />
            <div className={styles.connect} dangerouslySetInnerHTML={{ __html: content }}/>
        </div>
    )
}


export default provider(Page)