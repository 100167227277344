import React from "react";
import styles from "./welcomeCard.module.css";

const WelcomeCard = ({ color, name, description, onClick }) => {
  
  return (
    <article
      onClick={onClick}
      className={styles.root}
      style={{ backgroundColor: color }}
    >
      <h4>{name}</h4>
      <p>{description}</p>
    </article>
  );
};

export default WelcomeCard;
