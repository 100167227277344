import React from "react";

import styles from "./cartCounter.module.css";

const CartCounter = ({ 
  count, 
  type, 
  onClick, 
  cta,
  disabled, 
  label
 }) => {
  return (
    <div className={styles[type]} onClick={onClick}>
      <span>
        <span>
          <p>{count}</p>
          <p>{label}</p>
        </span>
        <a className={disabled && styles.disabledButton} >{cta}</a>
      </span>
    </div>
  );
};

export default CartCounter;
