import React from "react";
import { Header, BottomNav } from "../components/components";

import styles from "./layout.module.css";

const Layout = ({ children, count }) => {
  return (
    <div className={styles.layout}>
      <Header />
      {children}
      <BottomNav count={count}/>
    </div>
  );
};

export default Layout;
