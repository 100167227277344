import axios from "axios";

const ROOT = "https://vserve-api.valet2you.in/api/v2";
export const IMAGE_ROOT_URL = "https://master-vserve.s3.ap-south-1.amazonaws.com/hotels/"
export const FOOD_IMAGE_ROOT_URL = 'https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/'
export const SERVICE_IMAGE_ROOT_URL = 'https://master-vserve.s3.ap-south-1.amazonaws.com/hotel_services/'

export const login = ({ hotelId, email, lastName }) =>
  axios({
    method: "POST",
    url: `${ROOT}/register-guest`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      hotel_enc_id: hotelId,
      email: email,
      name: lastName,
      device_type: "web",
      device_token: "ok",
    },
  });

export const getPrivateResource = (endpoint) => {
  const ACCESS_TOKEN = localStorage.getItem("hotel_token") || "";
  return axios({
    method: "GET",
    url: ROOT + endpoint,
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  });
};

export const postPrivateResource = (endpoint, payload) => {
  const ACCESS_TOKEN = localStorage.getItem("hotel_token") || "";
  return axios({
    method: "POST",
    url: ROOT + endpoint,
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: payload,
  });
};

export const postPrivateResourceFormData = (endpoint, payload, attachment) => {
  const ACCESS_TOKEN = localStorage.getItem("hotel_token") || "";
  const payloadWithFile = new FormData();

  Object.keys(payload).forEach(key => payloadWithFile.append(key, payload[key]))
  payloadWithFile.append(attachment.type, attachment.file);


  return axios({
    method: "POST",
    url: ROOT + endpoint,
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
      'Content-Type': 'multipart/form-data'
    },
    data: payloadWithFile,
  });
};

export const getPublicResource = (endpoint) =>
  axios({
    method: "GET",
    url: ROOT + endpoint,
  });
