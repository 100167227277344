import React from 'react'
import {Link} from 'react-scroll';

import styles from './menu.module.css'

const Menu = ({subcategories, onClose}) => {
    return (
    <div className={styles.menu} >
           {subcategories.map(subcategory => (
               <Link
               activeClass="active"
               to={subcategory.id}
               spy={true}
               smooth={true}
               offset={-160}
               duration= {500}
               >
               <p  onClick={onClose} key={subcategory.id}>{subcategory.name}</p>
               </Link>
           ))}
    </div>  );
}
 
export default Menu;