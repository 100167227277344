import React from "react";
import styles from "./textArea.module.css";

const TextArea = ({ value, onChange }) => {
  return (
    <textarea
      className={styles.textArea}
      rows={4}
      value={value}
      onChange={onChange}
    />
  );
};

export default TextArea;
