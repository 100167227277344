import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ProtectedRoute from "./utils/protectedRoute";
import Layout from "./layout/layout";

import { Loading } from "./components/components";
import { getPublicResource } from "./services/api";

import translations from "./assets/mock-translations.json";

import {
  Connect,
  Landing,
  Laundry,
  LaundryThankYou,
  Login,
  LanguageSelector,
  OrderFood,
  FoodThankYou,
  PlaceRequest,
  Reuse,
  Shield,
  Spa,
  SpaThankYou,
  TvGuide,
  Welcome,
  CheckoutFood,
  CheckoutLaundry,
  CheckoutSpa,
  CheckoutPlaceRequest,
  RequestThankYou
} from "./views";

export const HotelContext = createContext({});

const App = () => {
  const [loading, setLoading] = useState(true);
  const [globalState, setHotelDetails] = useState({});

  useEffect(() => {
    const urlProps = window.location.pathname.split("/");
    const existingParam = localStorage.getItem("hotel_id") || false;

    if (!existingParam) {
      localStorage.setItem("hotel_id", urlProps[urlProps.length - 2]);
      localStorage.setItem("room_no", urlProps[urlProps.length - 1]);
    }

    const hotelID = existingParam || urlProps[urlProps.length - 2];

    if (navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        console.log('latitude -->', latitude);
        console.log('longitude -->', longitude);
      });
    }

    (async function () {
      try {
        const {
          data: { data },
        } = await getPublicResource(`/guest/hotel/details/${hotelID}`);
        setHotelDetails({
          hotelDetails: data,
          translations,
        });
        window.history.pushState('', '', '/')
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const isAvailable = (service) => {
    return globalState.hotelDetails["hotel_services"].find(
      (item) => item.type === service
    );
  };

  return (
    <HotelContext.Provider value={globalState}>
      <Router>
        <Layout>
          <Switch>
            {isAvailable("laundry") && (
              <ProtectedRoute path="/laundry" component={Laundry} />
            )}
            {isAvailable("ird") && (
              <ProtectedRoute path="/ird" component={OrderFood} />
            )}
            {isAvailable("spa") && (
              <ProtectedRoute path="/spa" component={Spa} />
            )}
            {isAvailable("ird") && (
              <ProtectedRoute path="/checkout-food" component={CheckoutFood} />
            )}
            {isAvailable("laundry") && (
              <ProtectedRoute
                path="/checkout-laundry"
                component={CheckoutLaundry}
              />
            )}
            {isAvailable("spa") && (
              <ProtectedRoute path="/checkout-spa" component={CheckoutSpa} />
            )}
            {isAvailable("laundry") && (
              <ProtectedRoute
                path="/laundry-thank-you"
                component={LaundryThankYou}
              />
            )}
            {isAvailable("spa") && (
              <ProtectedRoute path="/spa-thank-you" component={SpaThankYou} />
            )}
            {isAvailable("ird") && (
              <ProtectedRoute path="/food-thank-you" component={FoodThankYou} />
            )}
            {isAvailable("connect") && (
              <Route path="/connect" component={Connect} />
            )}
            {isAvailable("conserve") && (
              <Route path="/conserve" component={Reuse} />
            )}
            {isAvailable("project_shield") && (
              <Route path="/project_shield" component={Shield} />
            )}
            {isAvailable("tv_guide") && (
              <Route path="/tv_guide" component={TvGuide} />
            )}

            <Route path="/login" component={Login} />
            <Route path="/set-language" component={LanguageSelector} />
            <ProtectedRoute path="/place-request" component={PlaceRequest} />
            <ProtectedRoute path="/checkout-place-request" component={CheckoutPlaceRequest} />
            <ProtectedRoute path="/request-thank-you" component={RequestThankYou}/>RequestThankYou
            <Route path="/welcome" component={Welcome} />
            <Route path="/" component={Landing} />
          </Switch>
        </Layout>
      </Router>
    </HotelContext.Provider>
  );
};

export default App;
