import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { SpaProvider as DataProvider } from "./checkout.provider";
import {
  Counter,
  Button,
  Loading,
  DatePickerComponent,
} from "../../components/components";
import { HotelContext } from "../../App";

import dateConfig from "./dateConfig";
import timeConfig from "./timeConfig";

import styles from "./spaCheckout.module.css";

const Checkout = ({ selectedItem, placeOrder, roomNumber }) => {
  const [guests, updateGuests] = useState(1);
  const [PUDate, setPUDate] = useState("");
  const [PUHour, setPUHour] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { translations } = useContext(HotelContext);
  const history = useHistory();
  const requestValid = guests && PUDate && PUHour;

  if (!Object.keys(selectedItem).length) {
    history.push("/");
    return <div />;
  }
  if (loading) {
    return <Loading />;
  }

  const placeOrderHandler = async () => {
    setLoading(true);
    setError("");
    try {
      await placeOrder({
        schedule: `${PUDate} ${PUHour}:00`,
        item: { spa_item_id: selectedItem.id, quantity: 1 },
        guestNumber: guests,
      });
      setLoading(false);
      return history.push("/spa-thank-you");
    } catch (e) {
      setLoading(false);
      setError(translations.ckGeneralError);
      console.log(e);
    }
  };

  const decrementGuests = () => {
    if (guests >= 1) {
      updateGuests(guests - 1);
      return;
    }
  };

  return (
    <>
      <div className={styles.closeButton} onClick={() => history.push("/spa")}>
        X
      </div>
      <p className={styles.warning}>
              &#x26A0;{translations.ckFoodTaxWarning}
            </p>
      <div className={styles.checkoutLayout}>
      {error && (
          <div className={styles.errorMessage}>
            {translations.ckLaundryError}{" "}
          </div>
        )}
       
        <h3 className={styles.spaCheckoutTitle}>
          {translations.ckSpaPackageSelected}
        </h3>
        <p className={styles.spaCheckoutService}>{selectedItem.name}</p>
        <p className={styles.spaPrice}>
          {translations.currency + selectedItem.price}
        </p>

        <span className={styles.divider}></span>

        <div className={styles.roomNumberNoBorder}>
          <p className={styles.placeRequestContentBold}>
            {translations.ckGeneralRoomNumber}
          </p>
          <p>{roomNumber}</p>
        </div>
        <div className={styles.guestsCounterSpa}>
          <p>{translations.ckGeneralGuests}</p>
          <Counter
            count={guests}
            increment={() => updateGuests(guests + 1)}
            decrement={() => decrementGuests()}
          />
        </div>
        <div className={styles.timeInputWrapper}>
          <p className={styles.selectTime}>
            {translations.ckLaundrySelectDate}
          </p>
          <DatePickerComponent
            placeholder={translations.selectDate}
            value={PUDate}
            config={dateConfig}
            confirmText={translations.ckTimepickerDone}
            cancelText={translations.ckTimepickerCancel}
            saveTime={time => setPUDate(time)}
            cType="date"
          />
        </div>
        <div className={styles.timeInputWrapper}>
          <p className={styles.selectTime}>
            {translations.ckLaundrySelectTime}
          </p>
          <DatePickerComponent
            placeholder={translations.selectTime}
            value={PUHour}
            config={timeConfig}
            confirmText={translations.ckTimepickerDone}
            cancelText={translations.ckTimepickerCancel}
            errorText={translations.ckTimepickerError}
            saveTime={time => setPUHour(time)}
            cType="hour"
            minHour={8}
            maxHour={17}
          />
        </div>
        <div className={styles.proceedSpaWrapper}>
        <Button
          label={translations.ckGeneralSubmitButton}
          type="proceed"
          onClick={() => placeOrderHandler()}
        />
          </div>
      </div>
    </>
  );
};

export default DataProvider(Checkout);
