import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import App from './App';
import favicon from './assets/favicon/favicon-16x16.png'
import './index.css';
import translations from './assets/mock-translations.json';
import * as serviceWorker from './serviceWorker';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

ReactDOM.render (
    <React.StrictMode>
        <Helmet>
            <meta charSet="utf-8"/>
             <title>{translations.generalPageTitle}</title>
            <link rel="canonical" href="http://mysite.com/example"/>
            <link rel="icon" type="image/png" href="./assets/favicon/favicon-16x16.png" sizes="16x16" />
        </Helmet>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.register();
