import React, { useState, useContext } from "react";
import { Button, Counter } from "../components";
import { HotelContext } from "../../App";

import veg from "../../assets/icons/veg.png";
import nonveg from "../../assets/icons/non-veg.png";

import styles from "./menuCard.module.css";

const MenuCard = ({
  name,
  type,
  price,
  description,
  increaseQuantity,
  decreaseQuantity,
  defaultQuantity,
}) => {
  const [count, setCount] = useState(defaultQuantity || 0);
  const { translations } = useContext(HotelContext);

  const increment = (newQuantity) => {
    setCount(newQuantity);
    increaseQuantity(newQuantity);
    return;
  };

  const decrement = (newQuantity) => {
    setCount(newQuantity);
    decreaseQuantity(newQuantity);
    return;
  };
  return (
    <article className={styles.menuCard}>
      <div className={styles.addButtonWrapper}>
        {count === 0 ? (
          <Button
            type="add"
            label={translations.ckGeneralCounterLabel}
            onClick={() => [setCount(1), increment(1)]}
          />
        ) : (
          <Counter
            count={count}
            increment={() => increment(count + 1)}
            decrement={() => decrement(count - 1)}
          />
        )}
      </div>
      <div className={styles.dishName}>
        <img src={type === "veg" ? veg : nonveg} />
        <h6>{name}</h6>
      </div>
      <span>
        {price > 0 ? `${translations.currency} ${price}` : `  `}
      </span>
      <span>{description}</span>
    </article>
  );
};

export default MenuCard;
