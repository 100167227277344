import React from "react"
import { useHistory } from "react-router-dom";

import provider from "./data.provider"
import arrow from "../../assets/icons/arrow-back.svg";

import styles from './tvGuide.module.css';
import { Loading } from "../../components/components";

const Page = ({
    content = ''
}) => {
    const history = useHistory();
    if (!content) {
        return (
            <Loading/>
        )
    }

   

    return (
        <div className={styles.tvGuideLayout}>
                <img
                  className={styles.arrowBack}
                  onClick={() => history.push("/")}
                  src={arrow}
                  alt="back"
                />
            <div className={styles.tvGuide} dangerouslySetInnerHTML={{ __html: content }}/>
        </div>
    )
}


export default provider(Page)