import React from "react";
import { useHistory } from "react-router-dom";

import { SpaCard,Loading } from "../../components/components";

import arrow from "../../assets/icons/arrow-back.svg";

import styles from "./spa.module.css";
import provider from "./data.provider";

const Spa = ({ items = [] }) => {
  const history = useHistory();
  if (!items.length) {
    return (
        <Loading/>
    )
}
  const renderSpas = () => {
    return items.map((category) => {
      return (
        <div className={styles.spaService} key={Math.random()}>
          <h5>{category.name}</h5>
          <span></span>
            {category['without_sub_category_items'].map((entry) => (
              <SpaCard 
              {...entry} 
              key={Math.random()}
              onClick={() => history.push("/checkout-spa", entry)}
               />
            ))}
        </div>
      );
    });
  };

  return (
    <div className={styles.spaLayout}>
      <img
        className={styles.arrowBack}
        src={arrow}
        onClick={() => history.push("/")}
        alt="back"
      />
      {renderSpas()}
    </div>
  );
};

export default provider(Spa);