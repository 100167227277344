import React, { useState, useContext } from "react";
import { Counter, Button, Loading } from "../../components/components";
import styles from "./checkout.module.css";
import { useHistory } from "react-router-dom";

import { PlaceRequestProvider as provider } from "./checkout.provider";
import { HotelContext } from "../../App";

const CheckoutPlaceRequest = ({ data, roomNumber, placeOrder }) => {
  const history = useHistory();
  const [guests, updateGuests] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { translations } = useContext(HotelContext);

  if (!Object.keys(data).length) {
    history.push("/");
    return <div />;
  }
  if (loading) {
    return <Loading />;
  }

  const { content, selectedRequests } = data;
  const selectedItems = Object.keys(selectedRequests).map((key) => {
    const matchingContent = content.find((cont) => cont.id == key);
    return {
    ...matchingContent,
    value: selectedRequests[key].value,
    quantity: matchingContent.type === "with-count" ? parseInt(selectedRequests[key].value) : null
  }
})

  const placeOrderHandler = async () => {
    setLoading(true);
    setError("");
    try {
      await placeOrder({
        items: selectedItems.map((entry) => ({
          connect_item_id: entry.id,
          description: entry.value,
          ...(entry.quantity && { quantity: entry.quantity })
        })),
        guestNumber: guests,
      });
      setLoading(false);
      return history.push("/request-thank-you");
    } catch (e) {
      setLoading(false);
      setError(translations.ckGeneralError);
      console.log(e);
    }
  };

  return (
    <div className={styles.checkoutLayout}>
      {error && (
        <div className={styles.errorMessage}>
          {translations.placeRequestError}
        </div>
      )}
      <p className={styles.placeRequestContentIntro}>
        {translations.ckRequestTitle}
      </p>
      {selectedItems.map((item) => (
        <p key={item.id} className={styles.placeRequestContent}>
          {item.name} {item.value}
        </p>
      ))}
      <div className={styles.roomNumber}>
        <p className={styles.placeRequestContentBold}>
          {translations.ckGeneralRoomNumber}
        </p>
        <p>{roomNumber}</p>
      </div>
      <div className={styles.guestsCounterSpa}>
        <p className={styles.placeRequestContentBold}>
          {translations.ckGeneralGuests}
        </p>
        <Counter
          count={guests}
          increment={() => updateGuests(guests + 1)}
          decrement={guests === 1 ? null : () => updateGuests(guests - 1)}
        />
      </div>
      <div className={styles.checkoutBox}>
        <Button
          label={translations.ckGeneralBackButton}
          type="back"
          onClick={() => history.push("/place-request")}
        />
        <Button
          label={translations.ckGeneralSubmitButton}
          type="proceed"
          onClick={() => placeOrderHandler()}
        />
      </div>
    </div>
  );
};

export default provider(CheckoutPlaceRequest);
