import React, { useContext } from "react";
import styles from "./thankYou.module.css";

import checkmark from '../../assets/icons/right.svg';
import { HotelContext } from "../../App"

const ThankYou = () => {
  const { translations } = useContext(HotelContext);
  return (
  <div className={styles.laundryConfirmation}>
      <img src={checkmark} alt=''/>
    <p>{translations.thankYouPlaceRequest}</p>
  </div>
)}

export default ThankYou;
