import React, { useState, useContext } from "react";
import { Button, Counter } from "../components";
import { HotelContext } from "../../App";

import styles from "./checkoutCard.module.css";

const MenuCard = ({
  name,
  price,
  addons: selectedAddons = [],
  sub_addons: availableAddons = [{}],
  inclTaxes,
  laundry = false,
  taxedPrice,
  increaseQuantity,
  decreaseQuantity,
  defaultQuantity = {
    quantity: 0,
  },
}) => {
  const [count, setCount] = useState(defaultQuantity.quantity);
  const { translations } = useContext(HotelContext);

  const increment = (newQuantity) => {
    setCount(newQuantity);
    increaseQuantity(newQuantity);
    return;
  };

  const decrement = (newQuantity) => {
    setCount(newQuantity);
    decreaseQuantity(newQuantity);
    return;
  };

  const addonsWithNames = Object.keys(selectedAddons)
    .map((key) => {
      if (selectedAddons[key]) {
        let allAvailableAddons = [];
        availableAddons.forEach((addonGroup) =>
          addonGroup.addons.forEach((subAddons) =>
            allAvailableAddons.push(subAddons)
          )
        );

        const item = allAvailableAddons.find((addon) => addon.id == key);

        return item ? item.name : null;
      }
    })
    .filter((n) => n);
  return (
    <article className={styles.menuCard}>
      <div className={styles.addButtonWrapper}>
        {count === 0 ? (
          <Button
            type="add"
            label={translations.ckGeneralCounterLabel}
            onClick={() => [setCount(1), increment(1)]}
          />
        ) : (
          <>
            <Counter
              count={count}
              increment={() => increment(count + 1)}
              decrement={() => decrement(count - 1)}
            />
            {!laundry && (
              <div>
                <span className={styles.taxedPrice}>
                  {translations.currency} {taxedPrice}
                </span>
                <span className={styles.inclTaxes}> {inclTaxes}</span>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.dishName}>
        <h6>{name}</h6>
      </div>
      <span className={styles.addonList}>{addonsWithNames.join(" | ")}</span>
      <span>
        {translations.currency} {price}
      </span>
    </article>
  );
};

export default MenuCard;
