import React, { useState, useContext } from "react";
import { Button, Counter } from "../components";
import { HotelContext } from "../../App";

import styles from "./laundryCard.module.css";

const LaundryCard = ({
  name,
  price,
  increaseQuantity,
  decreaseQuantity,
  defaultQuantity = {
    quantity: 0,
  },
}) => {
  const [count, setCount] = useState(defaultQuantity.quantity);
  const { translations } = useContext(HotelContext);

  const increment = (newQuantity) => {
    setCount(newQuantity);
    increaseQuantity(newQuantity);
    return;
  };

  const decrement = (newQuantity) => {
    setCount(newQuantity);
    decreaseQuantity(newQuantity);
    return;
  };
  return (
    <article className={styles.laundryCard}>
      <div className={styles.addButtonWrapper}>
        {count === 0 ? (
          <Button
            type="add"
            label={translations.ckGeneralCounterLabel}
            onClick={() => [setCount(1), increment(1)]}
          />
        ) : (
          <Counter
            count={count}
            increment={() => increment(count + 1)}
            decrement={() => decrement(count - 1)}
          />
        )}
      </div>
      <div className={styles.cloth}>
        <h6>{name}</h6>
      </div>
        <span>{translations.currency} {price}</span>
    </article>
  );
};

export default LaundryCard;
