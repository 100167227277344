import React, { useState } from "react";
import DatePickerInstance from "react-mobile-datepicker";
import styles from "./datePicker.module.css";

const DatePicker = ({
  placeholder,
  config,
  saveTime,
  value = "",
  confirmText,
  cancelText,
  errorText,
  cType,
  minHour,
  maxHour,
}) => {
  const [open, toggleOpen] = useState(false);
  const [error, setError] = useState("")

  const saveTimeHandler = (time) => {
    setError(null);
    if (cType === "date") {
      toggleOpen(false);
      return saveTime(time.toISOString().split("T")[0]);
    }

    const addZero = (minute) => (minute < 10 ? "0" + minute : minute);
    toggleOpen(false);

    if (minHour > time.getHours() || maxHour < time.getHours()) {
      setError(errorText)
      return;
    }
    return saveTime(`${addZero(time.getHours()) + ":" + addZero(time.getMinutes())}`);
  };

  return (
    <>
    <div className={styles.datePickerWrapper} >
      <a className={styles.select} onClick={() => toggleOpen(!open)}>
        {error || value || placeholder}
      </a>

      <DatePickerInstance
        cType={cType}
        value={new Date()}
        isOpen={open}
        dateConfig={config}
        showCaption={true}
        theme="ios"
        onSelect={saveTimeHandler}
        onCancel={() => toggleOpen(false)}
        confirmText={confirmText}
        cancelText={cancelText}
        min={config.min}
        max={config.max}
      />
    </div>
    </>
  );
};

export default DatePicker;
