import React from "react";
import styles from "./counter.module.css";

const Counter = ({ count, increment, decrement }) => {
  return (
    <div className={styles.counter}>
      <div onClick={decrement}>-</div>
      <div>{count}</div>
      <div onClick={increment}>+</div>
    </div>
  );
};

export default Counter;
