import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { LaundryCard, CartCounter, Loading } from "../../components/components";
import { HotelContext } from "../../App";

import arrow from "../../assets/icons/arrow-back.svg";

import styles from "./laundry.module.css";
import provider from "./data.provider";

const Laundry = ({ items = [], flattenedItems = [] }) => {
  const [selectedItems, selectItems] = useState([]);
  const [rerender, forceRender] = useState(Math.random())
  const history = useHistory();
  const { translations } = useContext(HotelContext);

  if (!items.length) {
    return (
        <Loading/>
    )
}

  const updateSelection = (quantity, id) => {
    const itemFromCatalogue = flattenedItems.find((item) => item.id === id);
    const alreadySelected = selectedItems.findIndex(
      (item) => item.id === itemFromCatalogue.id
    );

    if (alreadySelected == -1) {
      selectItems((contain) => [
        ...contain,
        { ...itemFromCatalogue, quantity: 1 },
      ]);
      return;
    }
    let mutableItems = selectedItems;
    const newQuantity = mutableItems[alreadySelected].quantity + quantity;

    if (!newQuantity) {
      selectItems((contain) => contain.filter((item) => item.id !== id));
      return;
    }
    mutableItems[alreadySelected].quantity = newQuantity;
    selectItems(mutableItems);
    return forceRender(Math.random());
  };

  const cartLengthAddons = () => {
    const sumCounter = selectedItems.map((i) => i.quantity).reduce((a, b) => a + b);
    return sumCounter;
  };

  const API_TARGET = "without_sub_category_items"

  const renderLaundries = () => {
    return items.map((category) => {
      return (
        <div className={styles.laundryService} key={Math.random()}>
          <h5>{category.name}</h5>
          <span></span>
          {category[API_TARGET].map((entry) => (
            <LaundryCard
              {...entry}
              key={Math.random()}
              defaultQuantity={selectedItems.find((item) => item.id === entry.id)}
              increaseQuantity={() => updateSelection(+1, entry.id)}
              decreaseQuantity={() => updateSelection(-1, entry.id)}
            />
          ))}
        </div>
      );
    });
  };

  return (
    <div className={styles.laundryLayout}>
      <img
        className={styles.arrowBack}
        src={arrow}
        onClick={() => history.push("/")}
        alt="back"
      />
      <p>{translations.laundryTitle}</p>
      {renderLaundries()}
      {selectedItems.length ? (
        <CartCounter
          type="viewCart"
          label="ITEM"
          cta={translations.laundryReview}
          onClick={() => history.push("/checkout-laundry", selectedItems)}
          count={cartLengthAddons()}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default provider(Laundry);
