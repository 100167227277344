import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Card } from "../../components/components";

import welcome from "../../assets/images/journey.png";
import reception from "../../assets/images/reception.jpg";

import { HotelContext } from "../../App";
import { SERVICE_IMAGE_ROOT_URL } from "../../services/api";

import styles from "./landing.module.css";

const Landing = () => {
  const { hotelDetails, translations } = useContext(HotelContext);
  const services = hotelDetails["hotel_services"].filter(
    (item) => item.type !== "mini_bar"
  );

  const getBanner = (service = false) =>
    service ? (
      <div
        className={
          service.size_type === "regular_square" ? styles.halfItem : null
        }
      >
        <Link key={service.id} to={service.name === "Place Your Request" ? "place-request" : service.type}>
          <Card
            heading={service.name}
            cover={SERVICE_IMAGE_ROOT_URL + service.banner}
          />
        </Link>
      </div>
    ) : (
      <div />
    );

  return (
    <div className={styles.landing}>
      {services.map((service) => getBanner(service))}
    </div>
  );
};

export default Landing;

/*

    <div className={styles.landing}>
      {getBanner(services[0])}
      <div className={styles.twoCardsPerRow}>
      {getBanner(services[1])}
      {getBanner(services[2])}
      </div>
      <div className={styles.twoCardsPerRow}>
      {getBanner(services[3])}
      {getBanner(services[4])}
      </div>
      <div className={styles.twoCardsPerRow}>
      {getBanner(services[5])}
      {getBanner(services[6])}
      </div>
    </div>

*/
